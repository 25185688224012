<template>
  <div
    id="table-other"
    class="table__container"
  >
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama penerima atau No. Invoice/SPT"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <FilterButton class="mr-4" @filterBase="getFilterBase" :is_transaction="true" :isPayoutOther="true" :get-data="getData" />
      </div>
    </div>

    <vue-good-table
      max-height="80vh"
      :columns="columns"
      :rows="items"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
        perPage: pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'"/>
        
        <span v-else-if="props.column.field == 'cetak'">
          <span v-if="checkPermission('detail pembayaran lainnya')">
            Berita Acara Pembayaran
          </span>
        </span>

      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Bukti -->
        <span v-if="props.column.field === 'file_attachment'">
          <div v-if="props.row.file_attachment">
            <b-img :src="props.row.file_attachment" width="60" />
          </div>
          <span v-else>-</span>
        </span>

        <!-- Column: Amount -->
        <span v-else-if="props.column.field === 'amount'">
          <span>{{ props.row.amount | formatAmount }}</span>
        </span>

        <!-- Column: Cetak -->
        <span v-else-if="props.column.field === 'cetak'">
          <div v-if="checkPermission('detail pembayaran lainnya')">
            <b-button
              class="bg-white border-8 border-0 px-5 py-1"
              @click="goToCetak(props.row)"
            >
              Cetak
            </b-button>
          </div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="checkPermission('batalkan pembayaran lainnya')">
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="cancelPayment(props.row.uuid)">
                <span>
                  Cancel Pembayaran
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <span class="text-capitalize">{{ props.formattedRow[props.column.field] }}</span>
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalInputOther />
    <ModalPayment :post-payment-other="postData" />
  </div>
</template>

<script>
import {
  BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalInputOther from '@/components/Payout/Other/Modal/ModalInputOther.vue'
import ModalPayment from '@/components/Payout/Other/Modal/ModalPayment.vue'
import FilterButton from '@/components/BaseFilter.vue'
// import ModalDebt from '@/components/Debt/Modal/ModalDebt.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalDebt,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    ModalPayment,
    ModalInputOther,
    BDropdown,
    BDropdownItem,
    FilterButton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        {
          label: 'Tipe',
          field: 'tipe_pembayaran',
        },
        {
          label: 'Jenis',
          field: 'jenis_pembayaran',
        },
        {
          label: 'Detail',
          field: 'detail',
        },
        {
          label: 'Penerima',
          field: 'receiver',
        },
        {
          label: 'No. Invoice/SPT',
          field: 'invoice_number_spt',
        },
        {
          label: 'Nilai',
          field: 'amount',
        },
        {
          label: 'Metode Pembayaran',
          field: 'payment_method',
        },
        {
          label: 'Bukti Bayaran',
          field: 'file_attachment',
        },
        {
          label: 'Berita Acara Pembayaran',
          field: 'cetak',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      items: [],
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      isLoading: false,
      formPayload: {},
      page: 1,
    }
  },
  watch: {
    '$store.state.payout.formPayloadOthers': {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
    filter: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  created() {
    if (checkPermission('list pembayaran lainnya')) {
      this.getData()
    }
  },
  methods: {
    getFilterBase(value) {
      this.$router.replace({query: ''})
      this.filter = {
        ...value,
      }
    },
    cancelPayment(id) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin membatalkan pembayaran ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('payout/cancelOthers', {
            uuid: `${id}/cancel`,
            params: '',
          }).then(() => {
            this.getData();
            successNotification(this, 'Success', 'Pembayaran berhasil dibatalkan');
          }).catch(err => {
            console.log(err);
          });
        }
      });
    },
    goToCetak(item) {
      this.$router.push({ name: 'invoice.index', params: { id: item.uuid }, query: { q: 'payment_report', type: 'other', pembayaran: 'lainnya', page: this.page || 1, date: this.$route.query.date || '' } })
    },
    getData(page = this.$route.query.page) {
      this.isLoading = true
      const queryParams = this.filter

      if (this.$route.query.date) {
        queryParams.filter_date_type = 'date';
        queryParams.payment_method= 'cash';
        queryParams.filter_date_value = this.$route.query.date;
      }
      queryParams.page = page
      this.page = page

      this.$store.dispatch('payout/getOthers', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.rows = result.data.data
        this.items = result.data.data.data
        this.isLoading = false
        // console.log(result);
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        this.isLoading = false
        console.log(err)
      })
    },
    postData() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin menambahkan pembayaran lainnya?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          const form = this.preparePayload()

          form.append('payment_method', this.$store.state.payout.payment_method || '')

          this.$store.dispatch('payout/postOthers', {
            uuid: '',
            payload: form,
          }).then(() => {
            this.isLoading = false
            successNotification(this, 'Success', 'Sukses menambahkan pembayaran')
            this.$bvModal.hide('modal-payout-other')
            this.$bvModal.hide('modal-payment')
            this.getData()
            // this.getVoucher()
          }).catch(err => {
            this.messages = err.response.data.meta.messages
            this.isLoading = false
            console.log(err)
          })
        }
      });
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#table-other {
  .vgt-wrap {
    overflow-x: scroll;
    background: white;
    .vgt-fixed-header {
      overflow-x: unset !important;
      table {

        thead {
          tr {
            th {
              border-bottom: 1px solid #ebe9f1;
              background-color: white;
              border-top: none;
              padding: 1.5rem 1.5rem;
              color: #7A7F94;
              text-transform: capitalize;
              font-size: 14px;
              vertical-align: middle;
              text-align: start;
            }
          }
        }
      }
    }
    .vgt-responsive {
      background-color: #ffffff;
      height: 68vh;
      overflow-x: unset !important;

      table {
        // width: 110rem;
        // max-width: unset;

        thead {
          tr {
            th {
              z-index: 10;
              border-bottom: 1px solid #ebe9f1;
              background-color: white;
              border-top: none;
              padding: 1.5rem 1.5rem;
              color: #7A7F94;
              text-transform: capitalize;
              font-size: 14px;
              vertical-align: middle;
              text-align: start;
            }
          }
        }
        tbody {
          tr {
            td {
              color: $dark;
              font-size: 14px;
              font-weight: 600;
              padding: 1.5rem 1.5rem;
              border-bottom: 1px solid #ebe9f1;
              white-space: pre-line;

            }
          }
        }
      }
    }
  }
}
</style>
